<template>
  <CFooter>
    <div>
      <strong>
          Emblem v4.0
      </strong>
    </div>
    <div>
      Copyright 2020-2024 Euphon Tech Ltd., Shanghai (CN). All rights reserved.
    </div>
    <div>
      <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备2022040990号
      </a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
